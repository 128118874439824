import http from '@/utils/http';

export function Login(params){
	return http.post('/v1/login', params)
}
export function Register(params){
	return http.post('/v1/register', params)
}
export function GetValiCode(params){
	return http.post('/v1/register_code', params)
}
export function GetReValiCode(params){
	return http.post('/v1/account/pwd_code', params)
}
export function ReWritePass(params){
	return http.put('/v1/account/pwd', params)
}